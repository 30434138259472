@import '../../../styles/customMediaQueries.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.infoContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading {
  composes: p from global;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.link {
  white-space: nowrap;
  color: var(--colorSecondary);
  font-weight: var(--fontWeightBold);
  font-size: var(--textPriceRegular);
  line-height: 19px;
  text-decoration-line: underline;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: inline;

    &::before {
      content: ' ';
    }
  }
}

.longWord {
  word-break: break-word;
  hyphens: auto;
}

.showMore {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;

  margin: 0 0 0 5px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.links {
  margin-top: 0;
  padding: 0;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (--viewportMedium) {
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    font-weight: var(--fontWeightBold);
    font-size: var(--textPriceRegular);
    line-height: 19px;
    color: var(--colorPrimary);
  }
}

.seller {
  font-size: var(--fontSizeSmall);
  font-weight: var(--fontWeightMedium);
  line-height: 17px;
}

.editProfileDesktop {
  composes: p from global;
  display: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  composes: p from global;
  display: inline;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    margin: 0;
  }
}
